.custom_graph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.landing-page-background {
  overflow-x: hidden;
  background: url('../images/login-background.jpeg') center/cover;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
}

.landing-page-background::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
}

.landing-page-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 0.5em;
}

.dots-loader {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: 33.3333333333% 50%;
  margin: auto auto 0.5em;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20%{background-position:0%   0%, 50%  50%,100%  50%}
  40%{background-position:0% 100%, 50%   0%,100%  50%}
  60%{background-position:0%  50%, 50% 100%,100%   0%}
  80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.report-document-background {
  overflow-x: hidden;
  background: url('../images/login-background.jpeg') center/cover;
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  border-top: 30px solid #0C969C;
}

.report-document-background::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
}

.report-document-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  width: 100%;
  padding: 0 0.5em;
}

.report-document-container > div {
  width: 100%;
  max-width: 450px;
  text-align: center;
}

.report-document-container h6 {
  background-color: #0C969C;
  width: 100%;
  padding: 8px;
  color: white;
  font-weight: lighter !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-bottom: 0;
}

.list-container {
  position: relative; 
  width: 100%;
  padding: 2em;
  overflow: hidden;
  border: 1px solid #EEEFF3;
  border-radius: 8px !important;
  background-color: white;
}

.list-container .logo {
  height: 100px;
}

.list-container .MuiListItemText-root > span {
  font-size: 13px !important;
  max-width: 290px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.homepage-button {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  font-weight: lighter;
  margin-top: 1.5em;
}

.sblc-modal .modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.sblc-card.card {
  background-color: white;
  padding: 2em;
  border-radius: 8px;
  margin: auto;
  width: 100%;
}

.sidePanel {
  background: transparent linear-gradient(180deg, #005583 0%, #7895B8 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #000000B8;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  color: #ffffff;

  .backButton {
    position: absolute;
    left: 5%;
    top: 0;

    a {
      letter-spacing: 0px;
      text-decoration: none;
      color: #ffffff;
      display: flex;
      align-items: center;
      gap: 30px;
      transition: 0.3s;
      padding-top: 20px;
    }
  }

  .logo-section {
    display: flex;
    height: 50px;
    color: #ffffff;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
  }

  .logOut {
    font-size: 18px;
    letter-spacing: 0px;
    text-decoration: none;
    align-items: center;
    gap: 30px;
    color: #ffffff;
    display: flex;
    padding: 0;
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 1329px;
  z-index: 101;
  background-color: #0619248c;
  backdrop-filter: blur(5px);
}

.openSidePanel {
  width: 75%;
}

@media (min-width: 500px) and (max-width: 992px) {
  .openSidePanel {
    width: 45%;
  }
}

.sidePanel a:hover {
  color: #f1f1f1;
}

.sidePanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.sidePanel .sidePanel_logo {
  border-radius: 5px;
  width: 50%;
  background: #fff;
  padding: 6px 14px;
  cursor: pointer;
}

.openbtn {
  font-size: 30px;
  cursor: pointer;
  color: white;
  background: transparent;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

.main {
  transition: .5s;
  margin-left: 0;
}

.mainMargened {
  margin-left: 250px;
}

.go_back {
  width: 90px;
  border-radius: 0 5px 5px 0;

  svg {
    width: 25px;
    height: 25px;
  }
}

.view-sblc-details {
  color: #8cb4ff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.view-sblc-details:hover {
  text-decoration: none;
}

.custom_card {
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  position: relative;
  padding: 10px;
  margin-top: 5px;

  .no_matching_data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .item {
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .item_selected {
    border-color: #007bff;
    background-color: #f0f0f0;
  }
}

.expandale_table {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.filers.row {
  height: 100px;
}

.expandale_table>.main_container>.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.table-container {
  flex: 1;
  overflow-y: auto;
}

.expandable_table_fixed_width {
  width: 200px;
}

.expandale_table .pagination_container .pagination_previous a,
.expandale_table .pagination_container .next a {
  color: var(--customLightBlue) !important;
  font-weight: 500 !important;
}

.expandale_table input[type='text'],
select {
  height: unset !important;
}

.main_table th {
  border: none;
}

.main_table td,
.sub_table td {
  cursor: pointer;
  padding: 0.5rem 1rem;
  line-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unclickable {
  pointer-events: none;
}

.sub_table {
  white-space: nowrap;
}

.pagination_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.sub_table td {
  display: inline-block;
  max-width: 300px;
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}

.sub_table td:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

.main_table {
  margin-top: 5px;
  display: inline-table;
  overflow: auto;
}

.subTable_container {
  max-height: 300px;
  overflow: auto;
  position: relative;
}

th {
  background: white;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.collapseRow {
  height: 100px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.collapseRow {
  height: 100px;
  overflow-y: auto;
}

.unCollapseRow {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.table-container {
  height: 400px;
  overflow-y: auto;
}

table>tbody>.unCollapseRow {
  height: 0;
  padding: 0;
  border-top: 0;
  overflow: hidden;
  line-height: 0;
  visibility: collapse;
  font-size: 0;
  transition: all ease-in-out 0.3s;
}

table>tbody>.unCollapseRow>.collapsedRow_bg {
  display: none !important;
}

table>tbody>.collapseRow {
  height: 20px;
  overflow-y: auto;
  padding: 5px;
  border-top: 1px solid #ddd;
  line-height: 20px;
  visibility: visible;
  transition: all ease-in-out 0.3s;
}

.main_table .collapsedRow_bg {
  background-color: #D8E7F8;
  display: table-cell;
}

.main_table .table_align_right {
  text-align: right;
}

.main_table .table_align_left {
  text-align: left;
}

.pagination {
  gap: 10px;
  justify-content: center;
  margin: 0;
}

tr {
  text-align: center;
}

.items_per_page {
  display: flex;
  align-items: center;
  gap: 5px;
}

.thead-main tr th {
  text-align: center;
  background-color: var(--customLightBlue);
  border: 1px solid #ffffff;
  color: #ffffff;
}

.custom_card {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
}

.thead-secondary tr th {
  background-color: var(--customPrimaryDark) !important;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 992px) {
  .pagination_container {
    flex-direction: column;
    gap: 10px;
  }
}

.csv_button {
  font-weight: 600;
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--customPrimaryDark) !important;
  color: #ffffff;
  font-size: 12.25px;

  &:hover {
    color: #ffffff !important;
    background-color: var(--customDarkBlue);
  }
}

.csv_button.disabled {
  pointer-events: none;
  background-color: #2D7299 !important;
  color: #ffffff;
}

.custom_loader {
  border-width: 3px !important;
  height: 25px !important;
  width: 25px !important;
  color: #0C969C !important;
}

.table_label {
  text-align: center;
  font-size: 13x;
  font-weight: 600;
  margin: 0 auto 5px;
  color: var(--customGrey);
  width: 100%;
}

.media-body {
  span {
    font-weight: 500;
    color: var(--customPrimaryDark);
  }

  h6 {
    color: var(--customGrey) !important;
    margin: 0;
    font-size: 12px;
  }
}

.count_icon {
  height: 25px;
  width: 25px;
  fill: var(--lightOrange);
}

.loading_area {
  height: 100px;
}

.page-heading {
  display: flex;
  text-decoration-color: var(--darkGrey);
  text-decoration-thickness: 2px;

  h2 {
    font-size: 20px;
    font-weight: 900;
  }
}

.page_heading_secondary {
  color: var(--customPrimaryDark);
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 10px;
}

.MuiAlert-root {
  align-items: center !important;
}

.flag_heading {
  padding: 8px;
  font-size: 12px;
  text-transform: capitalize;
  background-color: var(--lightOrange);
  color: #ffffff;
  font-weight: bolder;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.medium_heading {
  color: var(--lightOrange) !important;
  text-transform: capitalize;
  font-weight: bold;
  margin: 0;

  strong {
    font-size: 15px;
  }
}

.nav-tabs {
  gap: 10px;

  .nav-item {
    .nav-link {
      background-color: #ffffff !important;
    }

    .active {
      background-color: var(--customPrimaryDark) !important;
      color: #ffffff !important;
    }
  }
}

.custom_paper {
  height: 40px;
  width: 60px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterSelected {
  .css-13cymwt-control {
    background-color: #81384B;
    color: #ffffff;
  }

  .css-t3ipsp-control {
    background-color: #81384B;
    color: #ffffff;
  }
}

// Custom Dropdowns 
.custom_dropdowns .btn-secondary {
  background-color: #8C46D2 !important;
  width: 100px !important;
}

.dropDown_disabled .btn-secondary {
  background-color: transparent !important;
  width: 130px !important;
  height: 35px !important;
  color: var(--customPrimaryDark);
  border-color: var(--customPrimaryDark) !important;
  border-width: 2px !important;
}

// Material Date Range Style 
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px !important;
}

@media (max-width: 768px) {
  .sblc-details {
    flex-direction: column;
  }
}

.feature_data {
  .content_main_row {
    justify-content: center;
    gap: 15px;

    .filters,
    .line-chart-filters,
    .line-chart-single-filters {
      margin-bottom: 15px;
    }

    .line-chart-filters>div:first-child {
      margin-bottom: 10px;
    }

    .line-chart-single-filters {
      justify-content: center;
    }

    @media (max-width: 320px) {

      .filters_row .col-md-4>div,
      .line-chart-filters {
        flex-direction: column;
        align-items: center;
      }
    }

    @media (max-width: 400px) {
      .filters .col-md-6>div {
        flex-direction: column;
        align-items: center;
        margin-top: 1em;
      }

      .line-chart-single-filters {
        flex-direction: column;
        align-items: center;
      }
    }

    @media (max-width: 768px) {

      .col-md-5,
      .col-md-12 {
        padding: 0 !important;
      }

      .row {
        margin: 0 auto 10px;
      }
    }

    .my_graph {
      background: #ffffff;
      border-radius: 5px !important;
      z-index: 1;
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    }

    .my_table {
      height: 300px;
      overflow-y: auto;
      z-index: 0;
      background: #ffffff;
      border-radius: 5px !important;
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

      .table {

        td,
        th {
          font-weight: 400 !important;
        }
      }
    }
  }
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: var(--customPrimaryDark) !important;
}

.css-18dwmlb-MuiDataGrid-root {
  margin-bottom: 10px !important;
}

.css-1aj4h6-MuiDataGrid-root {
  border: none !important;
}

.add-button {
  position: absolute;
  top: 6.5px;
  right: 12px;
}

.report-filters {
  width: 30%;
}

.datagrid {
  max-width: 100%;
}

.sblc-input {
  position: sticky;
  top: 0;
  z-index: 1;
}

.entities .datagrid {
  height: calc(100vh - 51px);
}

.datagrid .MuiDataGrid-cell,
.datagrid .MuiDataGrid-columnHeaderTitle,
.datagrid .MuiTablePagination-displayedRows {
  font-size: 13px;
}

.MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
  min-height: 30px !important;
}

.MuiAlert-message button {
  margin-bottom: -1px;
}

.MuiBox-root.css-1tbggly {
  min-height: 2.8em;
}

.content-container.approve-document {
  gap: 10px;
}

.MuiAlert-message.css-zioonp-MuiAlert-message {
  padding: 4px;
}

.MuiAlert-action {
  padding-top: 0 !important;
}

.phone-input-container {
  position: relative;
  width: 100%;
}

.custom-phone-input {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
}

.w-fit {
  width: fit-content !important;
}

.MuiFormHelperText-root.Mui-error {
  margin: 0;
  text-transform: none;
}

.PhoneInput {
  position: relative;
}

.PhoneInputCountry {
  position: absolute !important;
  top: 10px;
  left: 15px;
  z-index: 1;
}

.kyc-form h6:not(:first-child) {
  margin-bottom: 1em !important;
}

.contact-person-mobile label {
  padding-left: 40px;
}

.contact-person-mobile input {
  padding-left: 53px;
}

.contact-person-mobile label.MuiInputLabel-shrink {
  padding-left: 0 !important;
}

.PhoneInputCountrySelect {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
}

#Business_Relation_Nature .MuiRadio-root,
#Form_Filled_By .MuiRadio-root {
  padding-top: 0.25em;
  padding-bottom: 0;
}

.kyc .upload-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.name-text-uppercase input {
  text-transform: uppercase;
}

.red {
  color: red !important;
}

.yellow {
  color: #B8860B !important;
  border-top: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.green {
  color: green !important;
}

.hidden {
  display: none !important;
}

.other-option {
  width: 100%;
}

.entities.sblc .MuiList-root.MuiList-padding.css-9ir9ky-MuiList-root,
.entities.sblc ul.MuiList-root.MuiList-padding.css-17mwkd6 {
  height: calc(100vh - 143px);
}

small.item {
  margin-top: -1px;
  font-size: 14px !important;
  font-weight: 600 !important;
}

small.title {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.entities.sblc .deal-form {
  max-height: calc(100vh - 115px);
  overflow-y: auto;
}

.entities.sblc ul.MuiList-root.MuiList-padding.css-pb3tjz-MuiList-root,
.entities.sblc ul.MuiList-root.MuiList-padding.css-1d62772 {
  height: calc(100vh - 108px);
}

.card.entities {
  height: calc(100vh - 85px);
  max-height: calc(100vh - 85px);
  border-radius: 8px;
  overflow-y: auto;
}

.entities.dropdown {
  min-height: calc(100vh - 68px);
}

.entities.dropdown .datagrid {
  height: calc(100vh - 110px);
}

.entities.dropdown .autocomplete {
  width: 18%;
  margin: 0 auto;
}

.form-modal .css-1wc848c-MuiFormHelperText-root {
  text-align: center !important;
}

.datagrid .header,
.form-modal .text-field {
  text-transform: capitalize !important;
}

.entities.sblc .datagrid {
  height: calc(100vh - 57px);
}

.datagrid .css-levciy-MuiTablePagination-displayedRows,
.datagrid .css-11cfq65-MuiTablePagination-displayedRows,
.datagrid p.MuiTablePagination-displayedRows.css-wqp0ve {
  margin-bottom: 0;
}

.datagrid .css-17jjc08-MuiDataGrid-footerContainer,
.datagrid .css-15lx25q-MuiDataGrid-footerContainer,
.datagrid .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar,
.datagrid .css-1gak8h1-MuiToolbar-root-MuiTablePagination-toolbar,
.datagrid .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-l45izh {
  min-height: 35px !important;
}

.datagrid.loading .css-eglki6-MuiLinearProgress-root {
  top: -15px !important;
}

.datagrid.no-data .css-204u17-MuiDataGrid-main>div:first-child {
  top: 24px !important;
}

.datagrid .css-1w5m2wr-MuiDataGrid-virtualScroller {
  height: calc(100vh - 198px) !important;
}

.content-container.entity-dropdown-container {
  padding: 0 !important;
}

.entity-dropdown-container > div:first-of-type {
  margin-top: 10px;
}

.entities.dropdown .datagrid .css-1w5m2wr-MuiDataGrid-virtualScroller {
  height: calc(100vh - 273px) !important;
}

.MuiOutlinedInput-root .MuiAutocomplete-endAdornment>.MuiAutocomplete-popupIndicator {
  margin-top: 0 !important;
}

.form-modal .modal-content {
  width: 100%;
}

.sblc-modal .modal-dialog {
  max-width: 800px !important;
}

.modal-title {
  font-size: 20px;
}

.modal-body span:not(legend span) {
  font-size: 15px;
}

.mass-update-modal.show-table .modal-dialog {
  max-width: 1000px !important;
}

.mass-update-modal:not(.show-table) .upload-container .MuiGrid2-root {
  width: 50%;
}

.form-modal .upload-files {
  margin-top: -1em;
  margin-bottom: 1em;
}

.form-modal .stsearch-box input {
  padding: 0 5px;
}

.form-modal .stsearch-box input,
.form-modal .stsearch-box input:focus,
.autocomplete .stsearch-box input,
.autocomplete .stsearch-box input:focus {
  border: none;
  outline: none;
}

.form-modal .stdropdown-container,
.autocomplete .stdropdown-container {
  height: 36px;
  border-radius: 4px;
}

.form-modal .stdropdown-menu,
.autocomplete .stdropdown-menu {
  width: 250px !important;
  left: 50%;
  transform: translate(-50%, 1%) !important;
}

.form-modal .stdropdown-menu .stdropdown-item,
.autocomplete .stdropdown-menu .stdropdown-item {
  height: 20px;
  padding: 0 5px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
}

.MuiButtonBase-root.Mui-disabled.MuiIconButton-root svg {
  color: rgba(0, 0, 0, 0.26) !important;
}

.form-modal .stdropdown-tools,
.autocomplete .stdropdown-tools {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.form-modal .stdropdown-tools:hover,
.autocomplete .stdropdown-tools {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
}

.css-1j9kmqg-MuiDataGrid-toolbarContainer {
  width: fit-content;
}

.css-1e2bxag-MuiDataGrid-root,
.css-hx4lix-MuiDataGrid-root {
  border: none !important;
}

.resizeLine {
  position: absolute;
  height: 100%;
  width: 4px;
  top: 0;
  right: -2px;
  cursor: col-resize;

  &:hover {
    background-color: #0AA1DD;
  }

  &:active {
    background-color: #0AA1DD;
  }
}

.css-hx4lix-MuiDataGrid-root .MuiDataGrid-withBorder {
  border: none !important;
}

.css-hx4lix-MuiDataGrid-root .MuiDataGrid-cell--textRight>div {
  justify-content: flex-end;
}

.css-ahj2mt-MuiTypography-root,
.deal-tabs .nav-item a,
.MuiAutocomplete-noOptions,
.MuiAutocomplete-loading,
.MuiStack-root.css-1kq5d21-MuiStack-root {
  font-size: 14px !important;
}

.hideFooter {
  .css-17jjc08-MuiDataGrid-footerContainer {
    display: none !important;
  }
}

[class="MuiDataGrid-root"] .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  border-right: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
}

[class*=MuiDataGrid-cell] {
  display: flex !important;
  line-height: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.export .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  rotate: 180deg;
}

.datagrid .css-i4bv87-MuiSvgIcon-root,
.datagrid .css-1umw9bq-MuiSvgIcon-root,
.datagrid svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-q7mezt {
  font-size: 20px !important;
}

.MuiPaper-root {
  word-break: break-word;
}

a:active,
a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
  outline-style: none;
}


.button-secondary {
  color: #ffffff !important;
  background-color: var(--customPrimaryDark) !important;
}

.missing-required-fields-container {
  max-height: 500px;
  overflow-y: auto;
}

.switch_button {
  min-width: 140px !important;
}

.button-secondary:hover {
  color: #ffffff;
}

.btn-group {
  border: 2px solid #B6B6B6;
}

.heading_primary {
  color: var(--customPrimaryDark);
  font-weight: bold;
}

.background_primary {
  background-color: #128CFE !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.background_secondary {
  background-color: #E46C36 !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.dropdown_selected .btn-secondary {
  background-color: var(--customPrimaryDark) !important;
  border-color: var(--customPrimaryDark) !important;
  // width: fit-content !important;
}

.tabs {
  display: flex;
  gap: 0 15px;
  list-style: none;
  top: -68px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.tabs li {
  cursor: pointer;
  padding: 4px 10px;
  width: 110px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
}

.tabs .activeTab {
  background-color: #F0F0F0 !important;
  color: #128CFE;
  border-radius: 5px 5px 0 0;
}

.css-hx4lix-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.filters_row {
  justify-content: center;
  align-items: center;
}

.dropDown_disabled .btn-secondary,
.dropdown_selected .btn-secondary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px !important;
}

.dropdown-menu.show {
  min-width: 300px !important;
  max-height: 300px !important;
  overflow-y: auto !important;
  padding: 5px;
}

.modal-dialog.modal-dialog-centered {
  min-width: auto !important;
}

.entities .modal-dialog.modal-dialog-centered {
  max-width: 800px !important;
}

.filters_row svg {
  cursor: pointer;
}

.custom_date_picker svg {
  fill: var(--customLightBlue) !important;
}

.date_selected {
  svg {
    fill: #ffffff !important;
  }
}

.card {
  background-color: #f7f7f7;
  padding: 5px 25px 30px;
  margin: 0 auto 25px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.add-icon-button {
  border: 1px solid !important;
  width: 38px !important;
  height: 38px !important;
  border-radius: 5px !important;
  background-color: var(--customPrimaryDark) !important;
  color: #ffffff !important;
}

.add-icon-button:disabled {
  background-color: rgba(0, 0, 0, 0.26) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.btn-danger {
  background-color: #d32f2f !important;
}

.MuiBox-root.css-wsew38 .MuiButton-root.Mui-disabled svg,
.MuiBox-root.css-wsew38 .MuiIconButton-root.Mui-disabled svg,
.MuiTableContainer-root .MuiIconButton-root.Mui-disabled svg {
  color: #bdbdbd !important;
  fill: #bdbdbd !important;
}

.color_error {
  fill: red !important;
  cursor: pointer;
}

.kyc-form,
.add-user-form {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  padding: 0.5em 1em;
  border-radius: 8px;
  height: calc(100vh - 52px);
}

.kyc.edit .kyc-form {
  height: calc(100vh - 66px);
}

.kyc-form {
  padding-right: 0;
}

.kyc-form > div {
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding-right: 1em;
}

.add-user-form .button-container {
  position: absolute;
  bottom: 1.5em;
  right: 1.5em;
}

.btn {
  font-size: 14px !important;
}

.add-user-form label {
  font-weight: 400;
}

.add-user-form label,
.add-user-form input {
  font-size: 13px !important;
}

.add-user-form .loader {
  position: absolute;
  right: 13px;
}

.multi-form .MuiIconButton-root {
  margin-top: 3px !important;
}

.multi-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -1.5em;
  column-gap: 0.5em;
}

.missing-required-fields.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 5px 10px;
}

.missing-required-fields .grid-header {
  display: contents;
  font-weight: bold;
  font-size: 14px;
}

.missing-required-fields .grid-row {
  display: contents;
}

.copy-pfi,
.copy-tranche {
  margin-right: 7px;
}

.copy-pfi.autocomplete .MuiFormControl-root,
.copy-tranche.autocomplete .MuiFormControl-root {
  margin-top: 0 !important;
}

.multi-form-container {
  margin-top: -1em;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters {
  padding: 1em 0 !important;
  margin: auto;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
  margin-bottom: 0.5em !important;
  margin-right: 0.5em;
  margin-left: 0.5em;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters {
  margin-right: 0.5em;
  margin-left: 0.5em;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
  align-items: center;
  padding: 0.7em 0 0.7em 0 !important;
  gap: 0.5em;
}

.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters {
  padding-top: 0 !important;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  display: none;
}

.w-color-swatch.w-color-github {
  background-color: #e5e7eb !important;
}

.date-picker input:disabled,
.datetime-picker input:disabled {
  color: black !important;
  -webkit-text-fill-color: black !important;
}

.report-buttons button:focus-visible,
.report-button button:focus-visible {
  color: white;
}

.user-form > div:first-child {
  margin-bottom: 13px;
}

.submit-buttons {
  position: absolute;
  bottom: 1.5em;
  right: 1.5em;
  background-color: white;
  display: flex;
  justify-content: end;
  width: 95%;
}

.documentation.report-buttons button:focus-visible {
  color: #1565c0;
}

.reports .date-picker .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined,
.mass-update .date-picker .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined {
  padding-right: 0.5em !important;
}

.reports .report-button button {
  width: 150px;
  justify-content: flex-start;
  gap: 0.5em;
}

.css-ihoyn6-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 25px !important;
}

@media (max-width: 570px) {
  .css-1t5wrdm-MuiDataGrid-filterForm {
    flex-direction: column;
  }

  .css-rne967-MuiFormControl-root-MuiDataGrid-filterFormDeleteIcon {
    align-items: flex-end;
  }

  .css-q68e8e-MuiFormControl-root-MuiDataGrid-filterFormColumnInput,
  .css-17vwkjt-MuiFormControl-root-MuiDataGrid-filterFormOperatorInput,
  .css-1h08ml-MuiFormControl-root-MuiDataGrid-filterFormValueInput {
    width: 100% !important;
    margin-bottom: 1em !important;
  }

  .css-1h08ml-MuiFormControl-root-MuiDataGrid-filterFormValueInput .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .sblc-modal .sblc-products div {
    flex-direction: column;
  }
}

// Resizable Data Grid
.css-18dwmlb-MuiDataGrid-root .MuiDataGrid-cell--textRight {
  [class*=makeStyles-root] {
    justify-content: flex-end !important;
  }
}

.css-1w5m2wr-MuiDataGrid-virtualScroller {
  margin-top: 28px !important;
}

[class*=makeStyles-root] {
  .cellValue {
    text-transform: lowercase !important;
    display: inline-block !important;
    font-size: 11.5px !important;

    &::first-line {
      text-transform: capitalize !important;
    }
  }
}

.error-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background: #f8d7da;
  color: #721c24;
  padding: 1rem;
  border-radius: 0.5rem;
  z-index: 1000;
}

.css-18dwmlb-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

[class*=MuiDataGrid-columnHeader] {
  background-color: var(--customPrimaryDark);
  color: #ffffff !important;
  min-height: 25px;
  max-height: 25px;
  height: 25px;
  line-height: 25px !important;
  text-transform: capitalize;
}

.custom_actions_header .MuiDataGrid-columnHeaderTitle {
  text-transform: none !important;
}  

.css-1tqrhto>.ps-menu-button:hover,
.css-1t8x7v1>.ps-menu-button:hover,
.css-x3jz15>.ps-menu-button:hover {
  color: #0C969C !important;
}

.w-color-swatch.w-color-github>div:last-child {
  outline: none !important;
  box-shadow: none !important;
}

.color-filter {
  margin: 0 !important;
}

@media (max-width: 320px) {
  .css-154sxbz-MuiPaper-root-MuiDataGrid-paper {
    min-width: 280px !important;
  }
}

@media (max-width: 1160px) {
  .document-generation-buttons {
    flex-direction: column !important;
  }
}

@media (max-width: 992px) {
  .filters_row {
    gap: 10px 30px;
    justify-content: start;
  }
}

// Date picker
.date_cont {
  justify-content: flex-end;
  display: flex;
  gap: 5px;

  svg {
    fill: var(--customPrimaryDark) !important;
  }
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 8px !important;
}

.css-mhc70k-MuiGrid-root>.tranche_name_col {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.date_selected input {
  color: #ffffff !important;
}

.date_selected .MuiIconButton-root {
  padding-left: 5px !important;
  color: #ffffff !important;
}

.date_selected .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  top: -12px !important;
}

.audit-trail .date-picker button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium,
.audit-trail .datetime-picker button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
  margin-right: 8px !important;
}

[class*=MuiAccordionSummary] {
  min-height: auto !important;
  margin: 0 !important;
}

[class*=MuiAccordionDetails-root] {
  padding-bottom: 0 !important;
}

.spinner-border {
  z-index: 2;
  width: 21px;
  height: 21px;
  text-align: center;
  color: #0A7075 !important;
}

@keyframes fadeDots {
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
}

.loader img {
  width: 70px;
}

.loader h6 {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.loader .dot {
  animation: fadeDots 1.5s infinite;
  display: inline-block;
  width: 3px;
  height: 3px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
}

.loader .dot:nth-child(1) { animation-delay: 0s; }
.loader .dot:nth-child(2) { animation-delay: 0.2s; }
.loader .dot:nth-child(3) { animation-delay: 0.4s; }

.btn-secondary:disabled {
  background-color: #6BA3BE !important;
  color: #ffffff !important;
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1kfabtt-MuiFormLabel-root-MuiInputLabel-root,
.css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root,
.css-160rfsr,
.css-1t470ln {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: unset !important;
  max-width: calc(100% - 50px) !important;
  margin: 0 !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.deal-form .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  margin-right: 8px;
}

.datetime-picker .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium.css-yxqbup {
  margin-right: 0 !important;
}

select:invalid,
::-webkit-input-placeholder {
  color: #666666 !important;
}

.global-search button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-aixiz9-MuiButtonBase-root-MuiIconButton-root {
  margin-top: -1px !important;
}

.global-search .MuiOutlinedInput-input {
  padding-top: 2.3% !important;
  padding-bottom: 2.3% !important;
}

.global-search label {
  top: -2px;
}

.MuiBox-root.css-wsew38 {
  align-items: center;
}

.filter-button {
  width: 100px;
  height: 34.69px;
  border-radius: 5px;
  border: none;
}

.reset-button {
  width: 20px;
  height: 34.69px;
  padding: 0 !important;
}

.reset-button .css-1d6wzja-MuiButton-startIcon,
.reset-button .css-1jsm74t-MuiButton-startIcon,
.reset-button .css-6xugel {
  margin: 0 0 1px 0;
}

.empty-table {
  margin-top: auto !important;
  margin-bottom: auto !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  max-width: min(100vw, 916px);
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  width: 100%;
}

.css-1qbc4x0-MuiYearPicker-root {
  max-height: 260px !important;
}

.css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition {
  min-height: 220px !important;
}

.css-ghi3gg-MuiDayPicker-weekContainer {
  margin: 0 !important;
}

.autocomplete .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.autocomplete .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.autocomplete .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator,
.autocomplete .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator:hover {
  margin-top: 1px;
  width: auto !important;
  padding: 1px !important;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input,
.autocomplete .css-9lxrin .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 4px 6px !important;
  overflow: hidden;
}

.autocomplete input,
.MuiFormLabel-root label {
  font-size: 13px !important;
}

.autocomplete.dropdown,
.generate-button {
  width: 190px !important;
  background-color: var(--customPrimaryDark);
  color: #fff !important;
}

.css-10y8whu-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-10y8whu-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-10y8whu-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  max-width: 200px;
  overflow: auto;
  padding-bottom: 0.5em;
}

.css-10y8whu-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-10y8whu-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-10y8whu-MuiSelect-select-MuiInputBase-input-MuiInput-input>.MuiBox-root.css-1ywof4l {
  flex-wrap: nowrap !important;
}

#account-menu ul.MuiList-root.MuiList-padding.MuiMenu-list.css-1toxriw-MuiList-root-MuiMenu-list,
ul.MuiList-root.MuiList-padding.MuiList-dense.MuiMenu-list.css-m9kink-MuiList-root-MuiMenu-list,
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1smcfvf-MuiButtonBase-root-MuiMenuItem-root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

input#Advance_Payment_To_Counterparty {
  padding: 16.5px 14px !important;
}

.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 12px;
  align-items: center;
}

.skeleton-container>span {
  border-radius: 4px;
  width: 305.33px;
  height: 35.69px;
}

.skeleton-container>span:last-child {
  width: 100%;
  height: 93px;
}

.kyc .file-table-header,
.kyc .file-table-content-container,
.kyc .upload-container .MuiLinearProgress-root {
  max-width: 700px;
}

.file-table-header {
  margin-top: 1em;
  padding: 0.3em 0;
  border: 1px solid #0C969C;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-weight: bold;
  background-color: var(--customPrimaryDark);
  color: #ffffff;
  text-align: center;
}

.file-table-content > div {
  border: 1px solid #dee2e6;
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
}

.file-table-content > div:nth-child(5) {
  justify-content: center;
}

.file-table-content a,
.file-table-content span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-table-content>div:nth-of-type(even),
.file-table-content>div:last-child {
  justify-content: center;
}

.file-table-content .MuiAutocomplete-root {
  padding: 0.3em 0;
}

.file-table-content-container {
  max-height: 300px;
  overflow-y: auto;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-1toxriw-MuiList-root-MuiMenu-list,
ul.MuiList-root.MuiList-padding.MuiList-dense.MuiMenu-list.css-m9kink-MuiList-root-MuiMenu-list,
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1smcfvf-MuiButtonBase-root-MuiMenuItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1smcfvf-MuiButtonBase-root-MuiMenuItem-root>span {
  padding-right: 0 !important;
}

span.MuiSwitch-root.MuiSwitch-sizeMedium.css-rehya9-MuiSwitch-root {
  height: 35px;
}

span.MuiSwitch-thumb.css-17jyosd-MuiSwitch-thumb {
  width: 16px;
  height: 16px;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
  min-height: 22px !important;
}

#account-menu .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault {
  margin-left: -3px !important;
}

#account-menu .MuiList-root>li {
  padding-top: 5px;
  padding-bottom: 5px;
}

#account-menu hr.MuiDivider-root.MuiDivider-fullWidth {
  margin-top: 0;
  margin-bottom: 0;
}

.autocomplete .css-18zlzor-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.autocomplete .css-1uu9glq-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.autocomplete .css-18zlzor-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.autocomplete .css-1uu9glq-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1ougq77-MuiButtonBase-root-MuiMenuItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.css-1usvt67-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.css-1usvt67-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 0 !important;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  top: calc(50% - 12px) !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary,
.bulk-actions button {
  font-size: 12px !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root,
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  font-size: 10px !important;
}

#panel-header>span.Mui-expanded {
  padding-bottom: 0 !important;
}

#panel-content {
  margin-top: 0.5em;
  align-items: center;
}

.css-n3z9fz-MuiPopper-root-MuiDataGrid-panel {
  top: -30px !important;
  left: 28% !important;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.autocomplete .css-9lxrin .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-bottom: 6px;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiAutocomplete-tag,
.autocomplete .css-9lxrin .MuiAutocomplete-tag {
  max-width: 125px !important;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input,
.autocomplete .css-9lxrin .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 0 !important;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiAutocomplete-tag,
.autocomplete .css-9lxrin .MuiAutocomplete-tag {
  max-width: 98px !important;
}

.autocomplete .css-1pw1k2y-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.autocomplete .css-1pw1k2y-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiAutocomplete-listbox {
  padding: 0 !important;
}

.autocomplete .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root,
.autocomplete .css-1kfabtt-MuiFormLabel-root-MuiInputLabel-root,
.autocomplete .css-1t470ln {
  font-size: 13px !important;
}

.sblc-modal .autocomplete .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root,
.sblc-modal .css-1kfabtt-MuiFormLabel-root-MuiInputLabel-root,
.sblc-modal .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: -2px !important;
}

.sblc-modal .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding: 0 !important;
  height: 32.13px !important;
}

.autocomplete .css-1q04gal-MuiDateCalendar-root {
  height: 270px !important;
}

.form-text-field {
  margin-top: 0 !important;
}

.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 39px;
}

.amount-error-message {
  font-size: 11.5px;
}

.form-text-field.country {
  margin-top: 1px !important;
  position: relative;
}

.dropdown-arrow {
  position: absolute !important;
  right: 8px;
  top: 6px;
}

.autocomplete .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 0;
  padding-bottom: 0;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.autocomplete .css-9lxrin .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  border-radius: 3px;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input,
.autocomplete .css-9lxrin .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  height: 26px;
}

.autocomplete .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.autocomplete .css-1ll44ll-MuiOutlinedInput-notchedOutline {
  bottom: 0;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  top: -5px !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 0 !important;
}

.autocomplete .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 2px !important;
}

.autocomplete .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  font-size: 13px;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.autocomplete .css-9lxrin .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 1px !important;
  padding-bottom: 0 !important;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input,
.autocomplete .css-9lxrin .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 0 !important;
}

.autocomplete .MuiAutocomplete-inputRoot {
  flex-wrap: nowrap !important;
}

.upload-container span {
  font-size: 13px;
}

.autocomplete .css-19fib4l-MuiAutocomplete-root,
.autocomplete .css-9lxrin {
  display: inline-flex;
  margin-right: 8px;
}

.css-3h9xom-MuiPaper-root-MuiAlert-root,
.css-1i9p219-MuiPaper-root-MuiAlert-root {
  align-items: center;
}

.css-ki1hdl-MuiAlert-action {
  padding-top: 0 !important;
}

.MuiGrid2-root.MuiGrid2-container {
  row-gap: 12px;
}

.deal-form .upload-container {
  margin-top: 0 !important;
}

.date-picker button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.css-15guoxn {
  margin-right: -14px !important;
}

.MuiGrid2-root {
  width: 100%;
}

.css-1ji7omv-MuiBackdrop-root,
.css-qc0l5n-MuiBackdrop-root,
.MuiBackdrop-root.css-19metyz {
  position: absolute !important;
}

.multi-form .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root,
.autocomplete .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 8px !important;
}

.MuiButton-colorPrimary,
.MuiTab-textColorPrimary.Mui-selected {
  font-size: 13px !important;
}

.MuiButton-colorPrimary.MuiButton-outlined:not(:disabled):not(.menu-list-container *),
.MuiTab-textColorPrimary.Mui-selected:not(.menu-list-container *) {
  color: #0C969C !important;
}

.MuiButton-colorPrimary.MuiButton-contained:not(:disabled):not(.menu-list-container *),
.MuiTabs-indicator:not(.menu-list-container *) {
  background-color: #0C969C !important;
}

.audit-trail-disclaimer {
  font-size: 15px;
  max-height: 400px;
  overflow-y: auto;
}

.motion-div {
  position: relative;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

.metric-icon {
  font-size: 25px !important;
}

.MuiButton-colorPrimary.MuiButton-outlined:not(:disabled):not(.menu-list-container *) {
  border-color: #0C969C !important;
}

.table-autocomplete .MuiFormControl-root {
  padding: 0 !important;
  margin: 0 !important;
}

.table-autocomplete fieldset {
  padding: 0 !important;
  border: none;
}

.autocomplete.table-autocomplete .css-19fib4l-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input,
.autocomplete.table-autocomplete .css-9lxrin .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.autocomplete.table-autocomplete .css-19fib4l-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,
.autocomplete.table-autocomplete .css-9lxrin .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 0 !important;
  padding-left: 3px !important;
}

.multi-form.autocomplete .MuiAutocomplete-root,
.copy-pfi.autocomplete .MuiAutocomplete-root,
.copy-tranche.autocomplete .MuiAutocomplete-root {
  width: 220px;
}

.copy-tranche {
  display: flex;
  align-items: center;
}

.reports .report-filter.autocomplete .css-8foop9-MuiFormControl-root-MuiTextField-root,
.mass-update .mass-update-filter.autocomplete .css-8foop9-MuiFormControl-root-MuiTextField-root,
:not(.report-filter).autocomplete .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root[class*="MuiFormControl-root"],
:not(.mass-update-filter).autocomplete .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root[class*="MuiFormControl-root"] {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.form-control[type="file"] {
  font-size: 13px !important;
}

label,
input,
select {
  font-size: 14px !important;
}

.kyc.edit .date-picker .MuiIconButton-edgeEnd {
  margin-right: 8px !important;
}

.kyc.edit .date-picker button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.css-15guoxn {
  margin-right: -6px !important;
}

span.MuiButtonBase-root.MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}

fieldset.MuiOutlinedInput-notchedOutline > legend {
  font-size: 0.65em !important;
}

.reports .date-picker input,
.mass-update .date-picker input,
.reports .color-filter input {
  padding: 7px 0 9px 14px !important;
}

.add-user-form .autocomplete .MuiAutocomplete-root {
  margin-right: 0;
}

.add-user-form .autocomplete fieldset {
  border-radius: 0.375rem;
  border: 1px solid #dee2e6;
}

.mass-update-modal .upload-container {
  margin: 0 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 3px !important;
  padding-right: 0;
}

.reports span.MuiButton-icon.MuiButton-startIcon,
.mass-update span.MuiButton-icon.MuiButton-startIcon {
  margin: auto !important;
  padding-bottom: 1px !important;
}

.reports .MuiGrid2-root,
.mass-update .MuiGrid2-root {
  padding-top: 0 !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.grid-container.icon-buttons button {
  padding: 0 !important;
}

.grid-container.icon-buttons > button:last-child {
  min-width: 90px;
  font-size: 12px;
}

.autocomplete .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding: 3px;
}

.MuiInputBase-root.MuiOutlinedInput-root input#Amount {
  padding: 0 0 0 14px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root input#SBLC_Id {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.file-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-container a span {
  width: 100% !important;
}

.delete-file {
  fill: red;
  cursor: pointer;
}

.input-fields {
  max-height: calc(100vh - 125px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

.entity-input-fields {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

.input-fields .upload-container {
  margin-right: -201px;
}

.uploaded-file {
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.file-link .MuiDataGrid-cellContent {
  color: #4183c4;
  cursor: pointer;
}

.business-unit.container {
  height: 100vh;
}

.fw-bold {
  font-weight: 400 !important;
}

.business-unit.container .content-container.deals.deal-selected .submit-buttons {
  bottom: 0.5em;
}

.document-generation-buttons span.MuiCircularProgress-root {
  width: 1.6em !important;
  height: 1.6em !important;
}

.w-32 {
  width: 32%;
}

.content-container.deals {
  padding: 0 !important;
  margin-top: 5px;
  height: calc(100vh - 52px);
}

.deal-tabs {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.form-section-button {
  margin-left: 0.5em;
}

.deal-tabs .nav-item a {
  color: #0C969C;
  border-radius: 0;
}

[class*="MuiDataGrid-menuIcon"] svg {
  color: #ffffff !important;
}

@media (max-width: 875px) {
  .tabs_bar {
    flex-direction: column;
    width: 100%;
  }
}